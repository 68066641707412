<template>
  <div class="user-login-contenedor flex" >
      <h3>Bienvenida al Sistema de Monitoreo de Igualdad</h3>
      <div class="login-form-contenedor flex">
        <div class="lg-form">
          <div class="input-contenedor">
              <label for="inputCorreo">correo electrónico</label>
              <input type="email" id="inputCorreo" v-model="usuario.correo">
          </div>
          <div class="input-contenedor">
              <label for="inputPass">Contraseña</label>
              <input type="password" id="inputPass" v-model="usuario.pass">
          </div>
          <button @click="goDashboard">Login</button>
          {{mensajeError}}
          <p>Todos los derechos reservados</p>
          <p>Instituto de la Mujer para el Estado de Morelos</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      usuario: {
        correo: '',
        pass: ''
      },
      mensajeError: ''
    }
  },
  methods:{
    goDashboard() {

      if( this.usuario.correo === 'admin@monitorea.imm.org.mx' ) {

        if( this.usuario.pass === 'Morelos-Transparente'){
          this.$router.push({name: 'welcome-screen'})
        }else{
          this.mensajeError = 'Usuario y/o contraseña incorrecta...'
          setTimeout(() => {
            this.limpiarFormulario()
        }, 2000);
        }
      } else {

        this.mensajeError = 'Usuario y/o contraseña incorrecta...'
        setTimeout(() => {
          this.limpiarFormulario()
        }, 2000);
      }
      
    },
    limpiarFormulario(){
            this.mensajeError = ''
            this.usuario.correo = ''
            this.usuario.pass = ''
    }
  }
}
</script>

<style lang="scss" escoped>
  .user-login-contenedor{
    width:100%;
    height:calc(100vh - 60px);
    flex-direction:column;
    
  }
  .login-form-contenedor{
    width:50%;
    height:400px;
    background-color: #fff;
    margin:40px auto;
    border-radius: 20px;
    
  }
  .lg-form{
    width:80%;
    height:300px;
    background-color: #f1f1f1;
    border-radius:20px;
    padding:14px 16px;
    display:flex; align-items:center; justify-content:center;
    flex-direction:column;

    & p{
      font-size:12px;
      margin:0;
    }
  }
  .input-contenedor{
    display:flex; flex-direction:column;
    margin:10px auto;
    width:50%;
  }
  button{
    width:50%;
    margin:20px auto;
    border:none;
    background-color: #392f5c;
    height:40px;
    color: #fff;
    border-radius:10px;
  }
   
</style>